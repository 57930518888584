<template>
  <div class="ManagerTasksDashboard">

    <CRow>
      <CCol>
        <h1> Aide - Présentation générale </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <p>
          <strong style="font-size: 1.4em;">🤖  Bonjour {{userFirstName}}, je suis l'EclerkBot et je suis là pour vous aider à utiliser au mieux l'application Eclerk !</strong>
        </p>
        <p class="presentation-question">
        ❓ Ok, je suis bien inscrit sur Eclerk, maintenant je fais quoi ?
        </p>
        <p class="presentation-answer"> 
          Tout d'abord, <strong>bienvenue sur Eclerk</strong> 👍 Nous sommes ravis de vous compter parmi nos membres. <br>
          Avant de commencer, je vous propose de <strong>paramétrer votre application</strong>
          en quelques minutes pour qu'elle convienne à votre activité. Selon votre rôle <em>(collaborateur, manager, administrateur et super-administrateur)</em>,
          le paramétrage de votre application comprend plus ou moins d'étapes : 
          <ul>
            <li v-if="userGroupContains(['BOSS'])">
              <CBadge color="success" shape="pill">Super-administrateur</CBadge> Veuillez tout d'abord 
              <strong>
                <router-link to="/company" target="_blank">
                  compléter et valider les informations de votre entreprise,
                </router-link>
              </strong> 
              notamment l'iban, bic et titulaire du compte. Ces informations sont indispensables pour 
              émettre des factures et apparaîtront dessus.
            </li>
            <li>
              <CBadge color="dark" shape="pill">Tous</CBadge> Vous pouvez ensuite
              <strong>
                <router-link to="/settings/profile-edition" target="_blank">
                    vérifier vos informations
                </router-link>. 
              </strong> 
            </li>
            <li> 
              <CBadge color="primary" shape="pill">Administrateur</CBadge> Vous pouvez ensuite 
              <strong>
                <router-link to="/administration/manage-mission-types" target="_blank">
                  renseigner les types de mission
                </router-link>
              </strong>
              que propose votre cabinet
              <em>(droit des affaires, droit pénal, etc.)</em>. Vous êtes libre d'en rajouter autant que vous souhaitez.
            </li>
            
            <li v-if="userGroupContains(['BOSS'])">
              <CBadge color="success" shape="pill">Super-administrateur</CBadge>Vous pouvez également définir 
              <strong>
                <router-link to="/parametres/default-convention-honoraires-b2c" target="_blank">
                  la convention d'honoraires types B2C 
                </router-link>
              </strong> et
              <strong>
                <router-link to="/parametres/default-convention-honoraires-b2b" target="_blank">
                   la convention d'honoraires types B2B
                </router-link> 
              </strong>  de votre cabinet
            </li>
            
            <li>
              <CBadge color="primary" shape="pill">Administrateur</CBadge> Finalement, il vous est possible d' 
              <strong>
                <router-link to="/administration/add-collaborateur" target="_blank">
                    ajouter des collaborateurs.
                </router-link>
              </strong> 
              Ceux-ci pourront ainsi accéder à leur espace professionnel.
              Attention, des rôles sont à définir pour chaque collaborateur.
            </li>
            
          </ul>
        </p>
        
        
        <p class="presentation-question">
        ❓ C'est bon, Eclerk est bien paramétré, je l'utilise de quelle manière maintenant ?
        </p>
        <p class="presentation-answer">
          Sur Eclerk, vous pouvez gérer vos :
        </p>
        <p>
          <strong><CIcon name="cil-address-book" /> CONTACTS : Il s'agit de personnes physiques (cliente ou non) qui se rapportent à votre activité</strong> et que vous souhaitez sauvegarder sous 
          Eclerk pour que vous et vos collaborateurs puissiez retrouver cette personne facilement.<br>
          <router-link to="/add-new-contact" target="_blank">
            <CIcon name="cil-arrow-right" /> Ajouter un contact
          </router-link>.
        </p>
        
        <p>
          <strong><CIcon name="cil-contact" /> CLIENTS : Il s'agit de personnes physiques ou morales pour qui votre cabinet travaille (ou va travailler).</strong> Vos clients 
          sont accessibles aux collaborateurs de votre cabinet. Une fois un client créé, vous pouvez créer des missions associées à ce client, lui lier des contacts et, si vous le souhaitez,
          créer un espace Eclerk à votre client.<br>
            <router-link to="/add-new-client" target="_blank">
                <CIcon name="cil-arrow-right" /> Ajouter un client 
            </router-link>.
        </p>
        
        <p>
          <strong><CIcon name="cil-copy" /> MISSIONS : Il s'agit des dossiers sur lesquels votre cabinet travaille. </strong>Ces dossiers sont reliés à un client et peuvent ou non être reliés à un contact. 
          Une fois une mission créée, vous pouvez lui affecter des collaborateurs et créer des priorités missions. Pour chaque mission, vous pouvez générer une facture,
          renseigner les tâches effectuées (section reporting), faire une demande de documents en ligne à votre client (via son espace Eclerk) et suivre votre mission en temps réel.<br>
          <router-link to="/add-new-mission" target="_blank">
            <CIcon name="cil-arrow-right" /> Ajouter une mission 
          </router-link>.
        </p>
        
        <p>
          <strong><CIcon name="cil-loop" /> PRIORITES MISSIONS : Il s'agit des actions en lien avec une mission sur laquelle votre cabinet travaille. </strong>
          Celles-ci peuvent être majeures ou mineures. Une fois votre priorité créée, vous lui affectez des collaborateurs qui reçoivent une notification
          et voient apparaître sur leur tableau de bord Eclerk les priorités missions sur lesquels ils doivent travailler.<br>
          Ces priorités et leur suivi vous permettent de suivre vos missions en temps réel.
        </p>
        
        <p>
          <em>Exemple global :</em> Je commence un dossier pour la direction juridique d'une entreprise. Je crée la fiche client de l'entreprise, puis créé la fiche 
            contact du directeur juridique et le relie directement à mon client. Je crée ensuite la mission associée à ce client et y affecte les collaborateurs
            amenés à travailler dessus.<br>
            Au jour le jour, je créé des priorités missions, y affecte les collaborateurs adéquats et suis l'avancement de la mission. Je peux, si je le souhaite, créer un 
            espace Eclerk à mon client et créer une demande de documents pour qu'il puisse me faire parvenir les pièces nécessaires directement via l'application. Mes collaborateurs
            renseignent, via l'application web ou mobile, leur reporting mission.
            
            Quand je le souhaite, je peux directement éditer la facture sur mon espace Eclerk et l'envoyer au client
        </p>
        
        
        <p class="presentation-question">
        ❓ Que dois-je faire au jour le jour quand je me connecte sur Eclerk ?
        </p>
        
        <p class="presentation-answer">
          Au jour le jour, je peux : 
          <ul>
            <li><strong>Suivre et gérer mes priorités missions,</strong> qui apparaissent directement sur mon <strong><router-link to="/" target="_blank">tableau de bord</router-link></strong></li>
            <li><strong><router-link to="/vos-taches" target="_blank">Remplir mon reporting journalier</router-link>.</strong> 
              Les utilisateurs <CBadge color="danger" shape="pill">Manager</CBadge> du cabinet pourront accéder au reporting global du cabinet.</li>
          </ul>
        </p>
        
        
        <p class="presentation-question">
        ❓ Au niveau de la facturation, que puis-je faire via Eclerk ?
        </p>
        
        <p class="presentation-answer">
          <CBadge color="danger" shape="pill">Manager</CBadge> Seuls les utilisateurs <em>Manager</em> peuvent gérer la facturation. Ils peuvent <strong>créer une facture liée à une mission en cours, ajouter directement les items de contenu 
          ainsi que leur prix puis télécharger leur facture en pdf</strong>. Les informations sont automatiquement intégrées dans la facture.<br>
          Au jour le jour, ils peuvent rajouter les paiements liés à chaque facture et ainsi identifier les factures en retard, payées, etc.
        </p>
        
        
        <p class="presentation-question">
        ❓ Que peut faire un client sur son espace Eclerk ?
        </p>
        
        <p class="presentation-answer">
          Vous pouvez créer un espace Eclerk à votre client. Celui-ci peut alors : 
          <ul>
            <li><strong>Accéder aux missions en cours,</strong> voir leurs caractéristiques (nom, date, description, type de facuration, etc.) et identifier les collaborateurs affectés à cette mission.</li>
            <li><strong>Voir vos demandes de pièces et charger directement en ligne les pièces demandées</strong>. Vous pouvez les récupérer sur le tableau de bord de votre mission.</li>
            <li><strong>Visualiser et télécharger directement ses factures</strong>.</li>
            <li><strong>Télécharger les reçus de ses paiements</strong>.</li>
          </ul>
          Dans tous les cas, pas de panique, vous pouvez <strong>visualiser l'espace Eclerk de votre client client avant de le créer</strong>. Vous pouvez également à tout moment 
          révoquer l'accès de votre client. 
        </p>
           
          
      </CCardBody>
    </CCard>
    
    <CCard>
      <CCardBody>
        <p style="font-size: 1.4em;" class="text-center">
          <strong> ❓ Vous ne trouvez pas votre réponse et vous avez besoin d'une aide personnalisée ? <a href="mailto:contact@eclerk.io"> Contactez-nous</a> et nous reviendrons vers vous au plus vite</strong>
        </p>
      </CCardBody>
    </CCard>
    
    <CCard class="mt-3">
      <CCardHeader>
        <strong> Rôles des utilisateurs</strong>
      </CCardHeader>
      <CCardBody>
        <aideRoleComponent />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import aideRoleComponent from '@/components/aideComponents/aideRoleComponent'
import userMixins from '@/mixins/userMixins'

export default {
  name: 'ManagerTasksDashboard',
  components: {
    aideRoleComponent,
  },
  mixins: [
    userMixins
  ],
  computed: {
    userFirstName () {
      return this.$store.state.user.userFirstName
    },
    companyStripeProductId () {
      return this.$store.state.user.companyStripeProductId
    }
  },
}
</script>

<style>
  .presentation-question {
    margin-top: 40px;
    font-weight: bold;
    font-size: 18px;
  }
</style>
